<!-- =========================================================================================
  File Name: Fournisseur view
  Description: view of fournisseur
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author Email: eudoxiedjetouan@gmail.com
========================================================================================== -->


<template>
  <div id="page-personnel-edit">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Liste Fournisseur" icon-pack="feather" icon="icon-users">
            <div class="tab-text">
              <fournisseur-list-tab></fournisseur-list-tab>
            </div>
          </vs-tab>
          <vs-tab label="Nouveau" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <fournisseur-add-tab></fournisseur-add-tab>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import FournisseurAddTab from './fournisseur_add.vue'
import FournisseurListTab from './fournisseur_list.vue'
export default {
  name: 'FournisseurView',
  components: {
    FournisseurAddTab,
    FournisseurListTab
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>

<style scoped>

</style>
