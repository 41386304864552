<template>
  <div>
    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vx-card :noShadow="true" :cardBorder="true" title="Infos générales">
          <div class="vx-row">
            <div class="vx-col  w-full">
              <vs-input class="w-full mt-4" label="Nom Fournisseur" :value="Nom" @change.native="Nom=$event.target.value" name="nom" />
            </div>
            <div class="vx-col  w-full">
              <vs-input class="w-full mt-4" label="NumCC" :value="NumCC" @change.native="NumCC=$event.target.value" name="NumCC" />
            </div>
            <div class="vx-col  w-full">
              <vs-input class="w-full mt-4" label="NumCb" :value="NumCb" @change.native="NumCb=$event.target.value" name="NumCb" />
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <div class="mt-4">
                <label class="text-sm">Délais de livraison</label>
                <flat-pickr v-model="DelaiLiv" class="w-full" name="DelaiLiv" />
              </div>
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <div class="mt-4">
                <label class="text-sm">Echéance</label>
                <flat-pickr v-model="Echeance" class="w-full" name="Echeance" />
              </div>

            </div>
          </div>

          <vs-textarea class="w-full mt-4" counter="250" label="Remarque" :counter-danger.sync="counterDanger" :value="Remarque" @change.native="Remarque=$event.target.value" />

          <div class="demo-alignment">
            <span>Espèce:</span>
            <div class="flex">
              <vs-checkbox v-model="PaiEspece"></vs-checkbox>
            </div>

            <span>Chèque:</span>
            <div class="flex">
              <vs-checkbox v-model="PaiCheq"></vs-checkbox>
            </div>

            <span>Virement:</span>
            <div class="flex">
              <vs-checkbox v-model="PaiVirement"></vs-checkbox>
            </div>

            <span>Transfert:</span>
            <div class="flex">
              <vs-checkbox v-model="PaiTransfert"></vs-checkbox>
            </div>
          </div>

          <!-- Gender -->

        </vx-card>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <vx-card :noShadow="true" :cardBorder="true" title="Adresses">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <vs-input class="w-full mt-4" label="Adresse" :value="adresse" @change.native="adresse=$event.target.value" name="Adresse" />

            <vs-input class="w-full mt-4" label="Telephone (*)" :value="Telephone" @change.native="Telephone=$event.target.value" name="Telephone" />

            <vs-select autocomplete v-model="pays" class="w-full select-large mt-4" label="Pays">
              <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item,index) in PaysData" class="w-full" />
            </vs-select>

            <vs-select autocomplete v-model="Quartier" class="w-full select-large mt-4" label="Quartier">
              <vs-select-item :key="index" :value="item.libelleQtier" :text="item.libelleQtier" v-for="(item,index) in QuartierData" class="w-full" />
            </vs-select>

            <vs-input class="w-full mt-4" label="Fax" :value="Fax" @change.native="Fax=$event.target.value" type="text"  name="Fax" />
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <vs-input class="w-full mt-4" label="Email (*)" :value="Email" @change.native="Email=$event.target.value" name="Email" />

            <vs-input class="w-full mt-4" label="Mobile" v-model="Mobile" :value="Mobile" @change.native="Mobile=$event.target.value" type="text"  name="Mobile" />

            <vs-select autocomplete v-model="ville" class="w-full select-large mt-4" label="Ville">
              <vs-select-item :key="index" :value="item.libelleVille" :text="item.libelleVille" v-for="(item,index) in VillesData" class="w-full" />
            </vs-select>

            <vs-input class="w-full mt-4" label="Localité" :value="localite" @change.native="localite=$event.target.value" type="text"  name="localite" />

            <vs-input class="w-full mt-4" label="Site" :value="web" @change.native="web=$event.target.value" type="text"  name="web" />
          </div>
        </div>


        </vx-card>


      </div>
    </div>
    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="saveFournisseur">Enregistrer</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Annuler</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'personnelNouveau',
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      IdUser: null,
      Nom: null,
      adresse: null,
      Telephone: null,
      pays: null,
      Quartier: null,
      Email: null,
      Mobile: null,
      ville: null,
      localite: null,
      Fax: null,
      web: null,
      NumCC: null,
      NumCb: null,
      DelaiLiv: null,
      Echeance: null,
      PaiEspece: false,
      PaiCheq: false,
      PaiVirement: false,
      PaiTransfert: false,
      Remarque: null,
      counterDanger: false
    }
  },
  computed: {
    PaysData () {
      return this.$store.state.pays.pays
    },
    VillesData () {
      return this.$store.state.pays.villes
    },
    QuartierData () {
      return this.$store.state.pays.quartiers
    }
  },
  methods: {
    saveFournisseur () {
      const payload = {
        Nom: this.Nom,
        adresse: this.adresse,
        Telephone: this.Telephone,
        pays: this.pays,
        Quartier: this.Quartier,
        Email: this.Email,
        Mobile: this.Mobile,
        ville: this.ville,
        localite: this.localite,
        Fax: this.Fax,
        web: this.web,
        NumCC: this.NumCC,
        NumCb: this.NumCb,
        DelaiLiv: this.DelaiLiv,
        Echeance: this.Echeance,
        PaiEspece: this.PaiEspece,
        PaiCheq: this.PaiCheq,
        PaiVirement: this.PaiVirement,
        PaiTransfert: this.PaiTransfert,
        Remarque: this.Remarque
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('fournisseur/createFournisseur', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.reset_data()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data () {
      this.Nom = null
      this.adresse = null
      this.Telephone = null
      this.pays = null
      this.Quartier = null
      this.Email = null
      this.Mobile = null
      this.ville = null
      this.localite = null
      this.Fax = null
      this.web = null
      this.NumCC = null
      this.NumCb = null
      this.DelaiLiv = null
      this.Echeance = null
      this.PaiEspece = false
      this.PaiCheq = false
      this.PaiVirement = false
      this.PaiTransfert = false
      this.Remarque = null
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getQuartiers () {
      this.$store.dispatch('pays/getQuartiers')
        .then(() => { })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getPays()
    this.getVilles()
    this.getQuartiers()
  }
}
</script>

<style scoped>

</style>
